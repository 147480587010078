<template>
  <div class="main">
    <v-speed-dial
      ref="speedDial"
      v-model="isDialOpen"
      fixed
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      style="right: 40px; bottom: 40px"
    >
      <template v-slot:activator> </template>
      <v-btn fab small @click.stop="openSettingsDialog">
        <v-icon :small="$vuetify.breakpoint.xs" color="secondary">
          settings
        </v-icon>
      </v-btn>
      <v-btn fab small @click.stop="$emit('start')">
        <v-icon :small="$vuetify.breakpoint.xs" color="secondary">
          play_arrow
        </v-icon>
      </v-btn>
    </v-speed-dial>
    <auto-scroll-settings-dialog :handle-start="toggleScrolling" />
    <float-menu
      :open-settings-dialog="openSettingsDialog"
      :handle-start="handleStart"
      :is-scrolling="isScrolling"
      :toggle-scrolling="toggleScrolling"
    />
  </div>
</template>

<script>
export default {
  name: "AutoScroll",
  components: {
    AutoScrollSettingsDialog: () =>
      import(
        "@/domains/therapy/steps/execute/presentation/components/AutoScrollSettingsDialog"
      ),

    FloatMenu: () =>
      import(
        "@/domains/therapy/steps/execute/presentation/components/FloatMenu"
      ),
  },

  data: () => ({
    isDialOpen: false,
    isScrolling: false,
  }),
  computed: {
    isMenuOpen: {
      get() {
        return this.$store.state.settings.drawer;
      },
    },
  },

  methods: {
    openSettingsDialog() {
      this.$store.commit("autoScroll/setSettingsDialog", true);
    },
    handleStart() {
      this.$emit("start");
    },
    handleStop() {
      this.$emit("stop");
    },
    toggleScrolling() {
      this.isScrolling = !this.isScrolling;
      if (this.isScrolling) {
        this.handleStart();
      } else {
        this.handleStop();
      }
    },
  },
};
</script>

<style scoped>
.float-button {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #1356a1;
  color: white;
  display: flex;
  justify-content: center;
  z-index: 1000;
  padding: 8px;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
}

.close-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 2px;
  cursor: pointer;
  z-index: 1100;
  background-color: gray;
  border-radius: 50%;
}

.main {
  padding: 20px;
}
</style>
